import React, { lazy } from 'react'
import { FrontDeskType } from '../Common'

const BillingSchedule = lazy(() =>
  import('./BillingSchedule').then(module => ({
    default: module.BillingSchedule,
  }))
)

export const dueInBSPath = `/frontdesk/duein/detail/billingschedule`
export const inHouseBSPath = `/frontdesk/inhouse/detail/billingschedule`
export const dueOutBSPath = `/frontdesk/dueout/detail/billingschedule`

export const BillingScheduleRoutes = [
  {
    props: { exact: true, path: dueInBSPath },
    component: <BillingSchedule mode={FrontDeskType.DueIn} />,
  },

  {
    props: { exact: true, path: inHouseBSPath },
    component: <BillingSchedule mode={FrontDeskType.InHouse} />,
  },
  {
    props: { exact: true, path: dueOutBSPath },
    component: <BillingSchedule mode={FrontDeskType.DueOut} />,
  },
]
